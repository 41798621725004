import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { INotification, INotificationRequestParams } from '../_interfaces';

@Injectable()
export class NotificationService {
  constructor(private http: HttpService) {
  }

  fetchNotifications({
                 limit = 20,
                 sort = 'asc',
                 from = null,
                 to = null,
                 residentId = null,
                 notificationClass = null
               }: Partial<INotificationRequestParams>): Observable<INotification[]> {
    let params: { [key: string]: any } = {
      limit: limit,
      sort: sort
    };
    if (from) {
      params = {...params, from: from};
    }
    if (to) {
      params = {...params, to: to};
    }
    if (notificationClass) {
      params = {...params, notificationClass: notificationClass};
    }
    return this.http.get<INotification[]>('/api/v2/pro/residents/' + residentId + '/notifications', params);
  }
}
