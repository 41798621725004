import { NgModule } from '@angular/core';
import { SafePipe } from './safe-pipe.pipe';
import { MessagePipe } from './message.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { UTCPipe, NewDatePipe } from './uct.pipe';

@NgModule({
  imports: [],
  declarations: [SafePipe, MessagePipe, EllipsisPipe, UTCPipe, NewDatePipe],
  exports: [SafePipe, MessagePipe, EllipsisPipe, UTCPipe, NewDatePipe],
})

export class PipeModule {

  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
