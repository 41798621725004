import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IDiaperOrder,
         IDiaperOrderDetail,
         IDiaperOrderRequestParams,
         IDiaperOrderImport,
         IDiaperOrderDetailImport,
         IDiaperOrderSummary,
         IDiaperOrderAverage,
         INursingHomeOrder,
         } from '../_interfaces';

@Injectable()
export class OrderService {
  constructor(private http: HttpService) {
  }

  fetchOrders({
                 from,
                 to,
                 nursingHomeName,
                 orderCode,
                 limit = 20
               }: Partial<IDiaperOrderRequestParams>,
               csv = false): Observable<IDiaperOrder[]> {
    let params: { [key: string]: any } = {};
    if (from) {
      params = {...params, from: from};
    }
    if (to) {
      params = {...params, to: to};
    }
    if (nursingHomeName) {
      params = {...params, nursingHomeName: nursingHomeName};
    }
    if (orderCode) {
      params = {...params, orderCode: orderCode};
    }
    if (limit) {
      params = {...params, limit: limit};
    }
    if (csv) {
      // CSV用
      return this.http.get<IDiaperOrder[]>('/api/v2/pro/traversal/orders/csv', params);
    } else {
      // 一覧用
      return this.http.get<IDiaperOrder[]>('/api/v2/pro/traversal/orders', params);
    }
  }

  fetchOrder(orderId: number): Observable<IDiaperOrder> {
    return this.http.get<IDiaperOrder>('/api/v2/pro/orders/' + orderId);
  }

  editOrder(params: Partial<IDiaperOrder>) {
    return this.http.put('/api/v2/pro/orders/:id', params);
  }

  updateOrder(params: Partial<IDiaperOrder>) {
    return this.http.put('/api/v2/pro/orders/:id', params);
  }

  receptOrder(params: Partial<IDiaperOrder>) {
    return this.http.put('/api/v2/pro/orders/:id/recept', params);
  }

  cancelOrder(params: Partial<IDiaperOrder>) {
    return this.http.put('/api/v2/pro/orders/:id/cancel', params);
  }

  deleteOrder(orderId: number) {
    return this.http.delete('/api/v2/pro/orders/:id', {id: orderId});
  }

  importOrder(params: Partial<IDiaperOrderImport>) {
    return this.http.post('/api/v2/pro/orders/csv-import', params);
  }

  fetchNursingHomeOrders(nursingHomeId): Observable<INursingHomeOrder[]> {
    return this.http.get<INursingHomeOrder[]>('/api/v2/pro/traversal/nursing-homes/' + nursingHomeId + '/orders');
  }

  fetchOrderSummary(orderId: number): Observable<IDiaperOrderSummary> {
    return this.http.get<IDiaperOrderSummary>('/api/v2/pro/traversal/orders/' + orderId + '/summary');
  }

  fetchOrderAverage(from, to): Observable<IDiaperOrderAverage> {
    let params: { [key: string]: any } = {};
    if (from) {
      params = {...params, from: from};
    }
    if (to) {
      params = {...params, to: to};
    }
    return this.http.get<IDiaperOrderAverage>('/api/v2/pro/traversal/orders/average', params);
  }
}
