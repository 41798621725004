import { trigger, state, animate, transition, style, keyframes } from '@angular/animations';

export const FADE_ANIMATION = trigger('fade', [
  state('0', style({display: 'none', opacity: 0})),
  state('1', style({display: 'block', opacity: 1})),
  transition('0 => 1', animate(300, keyframes([
    style({display: 'none', opacity: 0, offset: 0}),
    style({display: 'block', opacity: 0, offset: 0.1}),
    style({display: 'block', opacity: 1, offset: 1.0})
  ]))),
  transition('1 => 0', animate(300, keyframes([
    style({display: 'block', opacity: 1, offset: 0}),
    style({display: 'block', opacity: 0, offset: 0.9}),
    style({display: 'none', opacity: 0, offset: 1.0})
  ]))),
]);

export const FADE_OPACITY_ANIMATION = trigger('fade-inline', [
  state('0', style({opacity: 0})),
  state('1', style({opacity: 1})),
  transition('0 => 1', animate(300, keyframes([
    style({opacity: 0, offset: 0}),
    style({opacity: 1, offset: 1.0})
  ]))),
  transition('1 => 0', animate(300, keyframes([
    style({opacity: 1, offset: 0}),
    style({opacity: 0, offset: 1.0})
  ]))),
]);

export const FADE_OVERLAY_ANIMATION = trigger('fade-overlay', [
  state('0', style({display: 'none', opacity: 0})),
  state('1', style({display: 'block', opacity: 0.4})),
  transition('0 => 1', animate(200, keyframes([
    style({display: 'none', opacity: 0, offset: 0}),
    style({display: 'block', opacity: 0, offset: 0.1}),
    style({display: 'block', opacity: 0.4, offset: 1.0})
  ]))),
  transition('1 => 0', animate(200, keyframes([
    style({display: 'block', opacity: 0.4, offset: 0}),
    style({display: 'block', opacity: 0, offset: 0.9}),
    style({display: 'none', opacity: 0, offset: 1.0})
  ]))),
]);

export const FADE_OUT = trigger('fade-out', [
  state('0', style({display: 'none', opacity: 0})),
  state('1', style({display: 'block', opacity: 1})),
  transition('0 => 1', animate(0, keyframes([
    style({display: 'none', opacity: 0, offset: 0}),
    style({display: 'block', opacity: 0, offset: 0.1}),
    style({display: 'block', opacity: 1, offset: 1.0})
  ]))),
  transition('1 => 0', animate(300, keyframes([
    style({display: 'block', opacity: 1, offset: 0}),
    style({display: 'block', opacity: 0, offset: 0.9}),
    style({display: 'none', opacity: 0, offset: 1.0})
  ]))),
]);
