import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import {
  INursingHomeDiaper,
  ICreateNursingHomeDiaper,
  IUpdateNursingHomeDiaper,
  INursingHomeLaxative,
  ICreateNursingHomeLaxative,
  IUpdateNursingHomeLaxative,
  INursingHomeDrinking,
  ICreateNursingHomeDrinking,
  IUpdateNursingHomeDrinking,
} from '../_interfaces';

@Injectable()
export class NursingHomeDiaperService {
  constructor(private http: HttpService) {
  }

  // 下剤関連
  fetchNursingHomeLaxatives(nursingHomeId): Observable<INursingHomeLaxative[]> {
    return this.http.get(`/api/v2/pro/nursing-homes/${nursingHomeId}/laxatives`);
  }

  createNursingHomeLaxative(nursingHomeId: number, params: ICreateNursingHomeLaxative) {
    return this.http.post(`/api/v2/pro/nursing-homes/${nursingHomeId}/laxatives`, params);
  }

  fetchNursingHomeLaxative(laxativeId: number): Observable<INursingHomeLaxative> {
    return this.http.get(`/api/v2/pro/laxatives/${laxativeId}`);
  }

  updateNursingHomeLaxative(laxativeId: number, params) {
    return this.http.put<IUpdateNursingHomeLaxative>(`/api/v2/pro/laxatives/${laxativeId}`, params);
  }

  deleteNursingHomeLaxative(laxativeId: number) {
    return this.http.delete(`/api/v2/pro/laxatives/${laxativeId}`);
  }

  // 飲水関連
  fetchNursingHomeDrinkings(nursingHomeId): Observable<INursingHomeDrinking[]> {
    return this.http.get(`/api/v2/pro/nursing-homes/${nursingHomeId}/drinks`);
  }

  createNursingHomeDrinking(nursingHomeId: number, params: ICreateNursingHomeDrinking) {
    return this.http.post(`/api/v2/pro/nursing-homes/${nursingHomeId}/drinks`, params);
  }

  fetchNursingHomeDrinking(drinkingId: number): Observable<INursingHomeDrinking> {
    return this.http.get(`/api/v2/pro/drinks/${drinkingId}`);
  }

  updateNursingHomeDrinking(drinkingId: number, params) {
    return this.http.put<IUpdateNursingHomeDrinking>(`/api/v2/pro/drinks/${drinkingId}`, params);
  }

  deleteNursingHomeDrinking(drinkingId: number) {
    return this.http.delete(`/api/v2/pro/drinks/${drinkingId}`);
  }

  // おむつ・パッド関連
  fetchNursingHomeDiapers(nursingHomeId, params?): Observable<INursingHomeDiaper[]> {
    return this.http.get(`/api/v2/pro/nursing-homes/${nursingHomeId}/diapers`, params);
  }

  createNursingHomeDiaper(nursingHomeId: number, params: ICreateNursingHomeDiaper) {
    return this.http.post(`/api/v2/pro/nursing-homes/${nursingHomeId}/diapers`, params);
  }

  fetchNursingHomeDiaper(nursingHomeDiaperId: number): Observable<INursingHomeDiaper> {
    return this.http.get(`/api/v2/pro/diapers/${nursingHomeDiaperId}`);
  }

  updateNursingHomeDiaper(nursingHomeDiaperId: number, params) {
    return this.http.put<IUpdateNursingHomeDiaper>(`/api/v2/pro/diapers/${nursingHomeDiaperId}`, params);
  }

  deleteNursingHomeDiaper(nursingHomeDiaperId: number) {
    return this.http.delete(`/api/v2/pro/diapers/${nursingHomeDiaperId}`);
  }
}
