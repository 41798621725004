import { Pipe, PipeTransform } from '@angular/core';
import { convertSQLDatetimeToUTCDate } from '../_utils/date.util';

@Pipe({name: 'utc'})
export class UTCPipe implements PipeTransform {
  transform(val: any): any {
    return this.UtcTransform(val);
  }

  private UtcTransform(datetime): any {
    if (typeof datetime !== 'string') {
      return;
    }

    const convertDate = convertSQLDatetimeToUTCDate(datetime);
    return convertDate;
  }
}

@Pipe({name: 'msDate'})
export class NewDatePipe implements PipeTransform {
  transform(val: any): any {
    return this.newDate(val);
  }

  private newDate(datetime): any {
    if (typeof datetime !== 'number') {
      return;
    }
    const date = new Date(datetime);
    return date;
  }
}
