import { throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { TokenUtil } from '../_utils/token.util';
import { AppConfig } from '../app.config';
import { ToastService } from './toast.service';
import { IErrorResponse } from '../_interfaces';

@Injectable()
export class HttpService {
  private host: string = AppConfig.getConfig().apiUrl;
  constructor(private injector: Injector,
              private toastService: ToastService,
              private http: HttpClient,
              ) {
  }

  get<T>(url: string, params: { [key: string]: any } = {}): Observable<T> {
    return this.http.get<T>(this.replaceUrl(url, params), this.getDefaultOptions(params)).pipe(
      tap((res) => this._normalServerError(res)),
      catchError(this._serverError));
  }

  post<T>(url: string, params: { [key: string]: any } = {}): Observable<T> {
    return this.http
      .post<T>(this.replaceUrl(url, params), params, this.getDefaultOptions()).pipe(
      tap((res) => this._normalServerError(res)),
      catchError(this._serverError));
  }

  put<T>(url: string, params: { [key: string]: any } = {}): Observable<T> {
    return this.http
      .put<T>(this.replaceUrl(url, params), params, this.getDefaultOptions()).pipe(
      tap((res) => this._normalServerError(res)),
      catchError(this._serverError));
  }

  delete<T>(url: string, params: { [key: string]: any } = {}): Observable<T> {
    return this.http
      .delete<T>(this.replaceUrl(url, params), this.getDefaultOptions(params)).pipe(
      tap((res) => this._normalServerError(res)),
      catchError(this._serverError));
  }

  postForm<T>(url: string, params: { [key: string]: any } = {}): Observable<T> {
    return this.http
      .post<T>(this.replaceUrl(url, params), params, this.getUploadOptions()).pipe(
      tap((res) => this._normalServerError(res)),
      catchError(this._serverError));
  }

  request(request: HttpRequest<any>): Observable<any> {
    return this.http.request(request);
  }

  /** :idのような文字列をparamsの値に置換する処理 */
  replaceUrl(url: string, params: { [key: string]: any} = {}): string {
    (url.match(/:([a-zA-Z0-9]+)/g) || []).forEach((value) => {
      const index = value.replace(/:/i, '');
      if (params[index]) {
        url = url.replace(new RegExp(value, 'i'), params[index]);
      }
      value = value.replace(/:/i, '');
    });

    return (!url.match(/http/g)) ? this.host + url : url;
  }

  getUploadOptions(params = {}): {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'json';
    withCredentials?: boolean;
  } {
    const token = TokenUtil.get();
    const header = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return {
      headers: header,
      params: params,
      responseType: 'json',
    };
  }

  getDefaultOptions(params = {}): {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'json';
    withCredentials?: boolean;
  } {
    return {
      headers: this._getDefaultHeaders(),
      params: params,
      responseType: 'json',
      withCredentials: true
    };
  }

  // 異常系エラー処理
  private _serverError(err: any) {
    return observableThrowError(err.error);
  }

  // 正常系のエラー処理
  private _normalServerError(res: IErrorResponse | any) {
    if (res != null && res.error) {
      this.toastService.error(res.error.message, `[${res.error.code}] ERROR`);
    }
  }

  private _getDefaultHeaders(): HttpHeaders {
    const token = TokenUtil.get();
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${token}`
    });
  }
}
