import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'app/_services/auth.service';
import { IAppReducerState } from 'app/_reducers';
import { IUserReducer } from 'app/_interfaces';
import { ToastService } from 'app/_services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  isLogin = false;

  constructor(@Inject('CONSTANTS') public CONSTANTS,
              private as: AuthService,
              private ts: ToastService,
              private fb: FormBuilder,
              private store: Store<IAppReducerState>) {
    this.store.select('user').subscribe((user: IUserReducer) => this.isLogin = user.isLogin);
    this.loginForm = fb.group({
      'email': new FormControl('', [Validators.required, this.CONSTANTS.VALIDATE_PATTERN.EMAIL]),
      'password': new FormControl('', Validators.required),
    });
  }

  clickLogin() {
    const params = this.loginForm.value;
    this.as.login(params);
  }
}
