import { throwError as observableThrowError,  Observable, timer } from 'rxjs';
import { take, map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { TokenUtil } from '../_utils/token.util';
import { HttpService } from '../_services/http.service';
import { AuthService } from '../_services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth, onAuthStateChanged } from "firebase/auth";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private router: Router;
  private http: HttpService;
  private as: AuthService;
  private fba: AngularFireAuth;
  private isTokenLoading = false;

  constructor(private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.http = this.injector.get(HttpService);
    this.router = this.injector.get(Router);
    this.as = this.injector.get(AuthService);
    this.fba = this.injector.get(AngularFireAuth);

    /** tokenの再取得中 */
    if (this.isTokenLoading) {
      console.log('接続待ち', request.url);
      return timer(500).pipe(take(1))
        .pipe(mergeMap(() => {
          console.log('接続要求', request.url);
          const token = TokenUtil.get();
          const req = request.clone({
            setHeaders: { Authorization: 'Bearer ' + token }
          });
          this.isTokenLoading = false;
          return this.http.request(req);
        }));
    }

    return this._checkToken(next, request);
  }

  private _checkToken(next, request) {
    if (TokenUtil.notExpired()) {
      console.log('接続中...', request.url);
      return next.handle(request);
    } else {
      if (this._refreshIdToken()) {
        const token = TokenUtil.get();
        const req = request.clone({
          setHeaders: { Authorization: 'Bearer ' + token }
        });
        return this.http.request(req);
      } else {
        return observableThrowError(new Error('Can\'t refresh the id token, please login again.'));
      }
    }
  }

  /**
    v2 Tokenの更新処理
    firebaseのユーザログイン状態に変更がありUSERオブジェクトが存在しなければ強制的にログイン画面に
  */
  private _refreshIdToken(): boolean {
    this.isTokenLoading = true;

    // const user = this.fba.auth.onAuthStateChanged(fbUser => {
    //   return fbUser;
    // });

    // if (!user || !this.fba.auth.currentUser) {
    //   return this._redirectLogout();
    // }
    const auth = getAuth();
    const user = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        return user;
        // ...
      } else {
        // User is signed out
        // ...
        this._redirectLogout();
      }
    });
    getAuth().currentUser.getIdToken(true).then((token) => {
      TokenUtil.set(token);
      this.isTokenLoading = false;
    });
    return true;
  }

  private _redirectLogout() {
    this.isTokenLoading = false;
    this.fba.signOut();
    this.as.logout();
    this.as.redirectPath = location.pathname;
    this.router.navigate(['/login']);
    return false;
  }
}
