import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'message'})
export class MessagePipe implements PipeTransform {
  transform(message: any): string {
    return this.messageTransform(message);
  }

  private messageTransform(message): string {
    if (typeof message !== 'string') {
      return '';
    }

    const urlRegExp = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const replacedText = message.replace(/</gi, '&lt;')
      .replace(/>/gi, '&gt;')
      .replace(/\n/g, '<br>')
      .replace(urlRegExp, '<a href="$1" target="_blank">$1</a>');
    return replacedText;
  }
}
