import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IUser, IUserRequestParams } from '../_interfaces';

@Injectable()
export class UserService {
  constructor(private http: HttpService) {
  }

  createUser(params: Partial<IUser>) {
    return this.http.post('/api/v2/pro/users', params);
  }

  updateUser(params: Partial<IUser>) {
    return this.http.put('/api/v2/pro/users/:id', params);
  }

  deleteUser(id) {
    return this.http.delete('/api/v2/pro/users/' + id, null);
  }

  fetchUsers({
               limit = null,
               nursingHomeGroupId = null,
               nursingHomeId = null
             }: Partial<IUserRequestParams>) {
    let params: { [key: string]: any } = {};
    if (limit) {
      params = {...params, limit: limit};
    }

    if (nursingHomeId) {
      return this.http.get('/api/v2/pro/traversal/nursing-homes/' + nursingHomeId + '/users', params);
    }

    if (nursingHomeGroupId) {
      return this.http.get('/api/v2/pro/traversal/nursing-home-groups/' + nursingHomeGroupId + '/users', params);
    }

    return this.http.get('/api/v2/pro/traversal/users', params);
  }

  fetchUser(userId: number) {
    return this.http.get('/api/v2/pro/users/:userId', {userId: userId});
  }
}
