import { Action } from '@ngrx/store';
import { IBreadcrumb } from '../_interfaces';

export const UPDATE_LIST  = '[Breadcrumb] update_list';

export class UpdateList implements Action {
  readonly type = UPDATE_LIST;

  constructor(public payload: Partial<IBreadcrumb>[]) {}
}

export type All = UpdateList;
