import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as moment from 'moment-timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/*
 * Date型をMySQLのDATE型のYYYY-MM-DDのStringに変換
 *
 * @params Date型
 * @return String 日付データ 例(1989-12-16)
 */
export function convertDate(sendDate: Date) {
  if (!sendDate || sendDate.toString() === 'Invalid Date') {
    return '';
  }

  const year: string = sendDate.getFullYear().toString();
  let month: string = (sendDate.getMonth() + 1).toString();
  let day: string = sendDate.getDate().toString();

  if (month.length === 1) {
    month = '0' + month;
  }

  if (day.length === 1) {
    day = '0' + day;
  }

  return year + '-' + month + '-' + day;
}

export function convertYmdhmToJst(ymdhm: string): number {
  if (!ymdhm) {
    return;
  }

  const year = parseInt(ymdhm.slice(0, 4), 10);
  const month = parseInt(ymdhm.slice(4, 6), 10) - 1;
  const day = parseInt(ymdhm.slice(6, 8), 10);
  const hour = parseInt(ymdhm.slice(8, 10), 10);
  const min = parseInt(ymdhm.slice(10, 12), 10);
  const datetime = moment(Date.UTC(year, month, day, hour, min)).tz('Asia/Tokyo').format();

  return new Date(datetime).getTime();
}

export function convertYmdhmsToDate(ymdhms: string): Date {
  if (!ymdhms) {
    return;
  }

  const year = parseInt(ymdhms.slice(0, 4), 10);
  const month = parseInt(ymdhms.slice(4, 6), 10) - 1;
  const day = parseInt(ymdhms.slice(6, 8), 10);
  const hour = parseInt(ymdhms.slice(8, 10), 10);
  const min = parseInt(ymdhms.slice(10, 12), 10);
  const second = parseInt(ymdhms.slice(12, 14), 10);
  const datetime = moment(Date.UTC(year, month, day, hour, min, second)).tz('Asia/Tokyo').format();

  return new Date(datetime);
}

export function convertSQLDatetimeToUTCDate(dt: string): string {
  if (!dt) {
    return;
  }
  return moment.utc(dt).format();
}

export function convertSQLDatetimeToJSTDate(dt: string): string {
  if (!dt) {
    return;
  }
  return moment.utc(dt).tz('Asia/Tokyo').format();
}

export function convertJstToUtc(date: Date): string {
  return moment(date).utc().format();
}

/** 指定された日の0時〜24時までUTCで取得する */
export function getUtcRange(date: Date): string[] {
  return [
    moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0)).tz('Asia/Tokyo').utc().format(),
    moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59)).tz('Asia/Tokyo').utc().format(),
  ];
}

/** 指定された日のUTCを取得する */
export function getUtc(date: Date): string {
  return moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
    .tz('Asia/Tokyo').utc().format();
}

// 指定された日時のその日の開始時間から終了時間の範囲を返す
// 開始時間が終了時間より後ろの場合は開始時間から翌日の終了時間までの範囲を返す
export function getDayStartToEndRange(timestamp: Dayjs, rangeStart: number, rangeEnd: number) {
  if (rangeStart <= rangeEnd) {
    return [
      dayjs(timestamp).tz('Asia/Tokyo').set('hour', rangeStart).set('minute', 0).set('second', 0).utc(),
      dayjs(timestamp).tz('Asia/Tokyo').set('hour', rangeEnd).set('minute', 0).set('second', 0).utc(),
    ];
  } else {
    return [
      dayjs(timestamp).tz('Asia/Tokyo').set('hour', rangeStart).set('minute', 0).set('second', 0).utc(),
      dayjs(timestamp).tz('Asia/Tokyo').add(1, 'day').set('hour', rangeEnd).set('minute', 0).set('second', 0).utc(),
    ];
  }
}
