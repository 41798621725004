import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IDiaper, IDiaperRequestParams } from '../_interfaces';

@Injectable()
export class DiaperService {
  constructor(private http: HttpService) {
  }

  fetchDiapers({
                 limit = 20,
                 diaperMakerName = null
               }: Partial<IDiaperRequestParams>): Observable<IDiaper[]> {
    let params: { [key: string]: any } = {
      limit: limit
    };
    if (diaperMakerName) {
      params = {...params, diaperMakerName: diaperMakerName};
    }
    return this.http.get<IDiaper[]>('/api/v2/pro/master/diapers', params);
  }

  fetchDiaper(diaperId: number): Observable<IDiaper> {
    return this.http.get<IDiaper>('/api/v2/pro/master/diapers/:id', {id: diaperId});
  }

  createDiaper(params: Partial<IDiaper>) {
    return this.http.post('/api/v2/pro/master/diapers', params);
  }

  editDiaper(params: Partial<IDiaper>) {
    return this.http.put('/api/v2/pro/master/diapers/:id', params);
  }

  updateDiaper(params: Partial<IDiaper>) {
    return this.http.put('/api/v2/pro/master/diapers/:id', params);
  }

  deleteDiaper(diaperId: number) {
    return this.http.delete('/api/v2/pro/master/diapers/:id', {id: diaperId});
  }
}
