import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';

export const RootRoutes: Routes = [
  {
    path: 'companies', loadChildren:
      () => import('./+admin/+company-manager/company-manager.module').then(m => m.CompanyManagerModule)
  },
  {
    path: 'announcements', loadChildren:
      () => import('./+admin/+announcement-manager/announcement-manager.module').then(m => m.AnnouncementManagerModule)
  },
  {
    path: 'order-mail-setting', loadChildren:
      () => import('./+admin/+order-mail-setting/order-mail-setting-manager.module').then(m => m.OrderMailSettingManagerModule)
  },
  {
    path: 'devices',
    loadChildren: () => import('./+admin/+device-manager/device-manager.module').then(m => m.DeviceManagerModule)
  },
  {
    path: 'b-devices',
    loadChildren: () => import('./+admin/+b-device-manager/b-device-manager.module').then(m => m.BDeviceManagerModule)
  },
  {
    path: 'diapers',
    loadChildren: () => import('./+admin/+diaper-manager/diaper-manager.module').then(m => m.DiaperManagerModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./+admin/+order-manager/order-manager.module').then(m => m.OrderManagerModule)
  },
  {
    path: 'operations',
    loadChildren: () => import('./+admin/+operation-manager/operation-manager.module').then(m => m.OperationManagerModule)
  },
  {
    path: 'deliveries',
    loadChildren: () => import('./+admin/+diaper-delivery-manager/diaper-delivery-manager.module').then(m => m.DiaperDeliveryManagerModule)
  },
  {
    path: 'analysis',
    loadChildren: () => import('./+admin/+analysis-manager/analysis-manager.module').then(m => m.AnalysisManagerModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('./+admin/+account-manager/account-manager.module').then(m => m.AccountManagerModule)
  },
  {
    path: 'nursing-homes',
    loadChildren: () => import('./+admin/+nursing-home-manager/nursing-home-manager.module').then(m => m.NursingHomeManagerModule)
  },
  {
    path: 'nursing-home-units',
    loadChildren: () => import('./+admin/+nursing-home-unit-manager/nursing-home-unit-manager.module')
      .then(m => m.NursingHomeUnitManagerModule)
  },
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  {
    path: 'companies/:companyId',
    loadChildren: () => import('./+company/company.module').then(m => m.CompanyModule)
  },
  { path: '', component: DashboardComponent, pathMatch: 'full' },
];

export const AdminRoutes: Routes = [
  {path: 'login', redirectTo: '', pathMatch: 'full'},
  {path: '', loadChildren: () => import('./+company/company.module').then(m => m.CompanyModule)},
];

export const MemberRoutes: Routes = [
  {path: 'login', redirectTo: '', pathMatch: 'full'},
  {path: '', loadChildren: () => import('./+company/company.module').then(m => m.CompanyModule)},
];

export const GuestRoutes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '**', redirectTo: 'login'},
  {path: '++ROOT', children: RootRoutes},
  {path: '++ADMIN', children: AdminRoutes},
  {path: '++MEMBER', children: MemberRoutes},
];

@NgModule({
  // imports: [RouterModule.forRoot(GuestRoutes, { relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(GuestRoutes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }

