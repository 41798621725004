import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IAnnouncement } from '../_interfaces';

@Injectable()
export class AnnouncementService {
  constructor(private http: HttpService) {
  }

  fetchAnnouncements(): Observable<IAnnouncement[]> {
    return this.http.get<IAnnouncement[]>('/api/v2/pro/announcements/list');
  }

  fetchAnnouncement(id: number): Observable<IAnnouncement> {
    return this.http.get<IAnnouncement>('/api/v2/pro/announcements/:id', {id: id});
  }

  createAnnouncement(params: Partial<IAnnouncement>) {
    return this.http.post('/api/v2/pro/announcements', params);
  }

  editAnnouncement(params: Partial<IAnnouncement>) {
    return this.http.put('/api/v2/pro/announcements/:id', params);
  }

  deleteAnnouncement(id: number) {
    return this.http.delete('/api/v2/pro/announcements/:id', {id: id});
  }
}
