import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-left-menu',
  templateUrl: './root-left-menu.component.html',
  styleUrls: ['./root-left-menu.component.scss']
})
export class RootLeftMenuComponent {

  constructor(@Inject('CONSTANTS') public CONSTANTS) { }

}
