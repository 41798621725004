import * as CompanyActions from '../_actions/company.action';
import { ICompanyReducer } from '../_interfaces';

export type Action = CompanyActions.All;

const initialState = {
  company: null,
  showCompanyMenu: true,
  activeNursingHomeId: null,
  activeNursingHomeUnitId: null,
  activeResidentId: null
};

export function reducer(state: ICompanyReducer = initialState, action: Action) {
  switch (action.type) {
    case CompanyActions.UPDATE:
      return {
        ...state,
        company: action.payload
      };
    case CompanyActions.SHOW_COMPANY_MENU:
      return {
        ...state,
        showCompanyMenu: action.payload
      };
    case CompanyActions.UPDATE_NURSING_HOME_ID:
      return {
        ...state,
        activeNursingHomeId: action.payload
      };
    case CompanyActions.UPDATE_NURSING_HOME_UNIT_ID:
      return {
        ...state,
        activeNursingHomeUnitId: action.payload
      };
    case CompanyActions.UPDATE_RESIDENT_ID:
      return {
        ...state,
        activeResidentId: action.payload
      };
    case CompanyActions.UPDATE_RESIDENTS:
      const { company } = state;
      const { nursingHomeId, residents } = action.payload;

      const nursingHomeKey = company.nursingHomes.findIndex(r => r.id === nursingHomeId);
      company.nursingHomes[nursingHomeKey].residents = residents;

      return {
        ...state,
        company: company
      };
    default:
      return state;
  }
}
