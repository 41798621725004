import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { IOrderPdfPosition } from 'app/_interfaces';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  pdfDownloadSubject: Subject<boolean> = new Subject();
  pdfDownloadState$ = this.pdfDownloadSubject.asObservable();

  constructor() {
  }

  download(pdfHeader: HTMLElement, tables: NodeListOf<Element>, fileName: string) {
    let headerProp = {ratio: 0, width: 0, height: 0};
    let tableProp = {ratio: 0, width: 0, height: 0};
    html2canvas(pdfHeader).then(async (header) => {
      const headerImgData = header.toDataURL('image/jpeg');
      // pdfをa4で指定
      const doc = new jspdf('p', 'mm', 'a4');
      // ページ番号の配置場所を指定　width: pdfの横幅の真ん中 height: pdfの高さから-10px
      const footerPosition = {width: doc.internal.pageSize.width / 2, height: doc.internal.pageSize.height - 10};
      headerProp = this.calcPdfPosition(headerProp, doc, header);
      // ヘッダーは一番上なのでx, y座標0に配置
      doc.addImage(headerImgData, 'JPEG', 0, 0, headerProp.width, headerProp.height);
      // ページ番号をフッターに設定
      doc.text(`${doc.getCurrentPageInfo().pageNumber}/${tables.length}`, footerPosition.width, footerPosition.height);
      for (let i = 0; i < tables.length; i++) {
        const table = tables[i];
        // HTMLElementを引数で受け取るためElementをHTMLElementとする
        const canvas = html2canvas(table as HTMLElement);
        await canvas.then((tableElement) => {
          if (i !== 0) {
            // 最初のテーブル以降のelementをcanvasデータで受け取った場合はページを増やす
            doc.addPage();
            doc.addImage(headerImgData, 'JPEG', 0, 0, headerProp.width, headerProp.height);
            doc.text(`${doc.getCurrentPageInfo().pageNumber}/${tables.length}`, footerPosition.width, footerPosition.height);
          }
          tableProp = this.calcPdfPosition(tableProp, doc, tableElement);
          const tableImgData = tableElement.toDataURL('image/jpeg');
          // テーブルはヘッダーの下に配置するのでy座標 = headerの高さに配置
          doc.addImage(tableImgData, 'JPEG', 0, headerProp.height, tableProp.width, tableProp.height);
          if (i === tables.length - 1) {
            doc.save(fileName);
            this.pdfDownloadSubject.next(false);
          }
        });
      }
    });
  }

  sendMail(pdfHeader: HTMLElement, tables: NodeListOf<Element>, fileName: string) {
    let headerProp = {ratio: 0, width: 0, height: 0};
    let tableProp = {ratio: 0, width: 0, height: 0};
    let formData;
    return html2canvas(pdfHeader).then(async (header) => {
      const headerImgData = header.toDataURL('image/jpeg');
      // pdfをa4で指定
      const doc = new jspdf('p', 'mm', 'a4');
      // ページ番号の配置場所を指定　width: pdfの横幅の真ん中 height: pdfの高さから-10px
      const footerPosition = {width: doc.internal.pageSize.width / 2, height: doc.internal.pageSize.height - 10};
      headerProp = this.calcPdfPosition(headerProp, doc, header);
      // ヘッダーは一番上なのでx, y座標0に配置
      doc.addImage(headerImgData, 'JPEG', 0, 0, headerProp.width, headerProp.height);
      // ページ番号をフッターに設定
      doc.text(`${doc.getCurrentPageInfo().pageNumber}/${tables.length}`, footerPosition.width, footerPosition.height);
      for (let i = 0; i < tables.length; i++) {
        const table = tables[i];
        // HTMLElementを引数で受け取るためElementをHTMLElementとする
        const canvas = html2canvas(table as HTMLElement);
        await canvas.then((tableElement) => {
          if (i !== 0) {
            // 最初のテーブル以降のelementをcanvasデータで受け取った場合はページを増やす
            doc.addPage();
            doc.addImage(headerImgData, 'JPEG', 0, 0, headerProp.width, headerProp.height);
            doc.text(`${doc.getCurrentPageInfo().pageNumber}/${tables.length}`, footerPosition.width, footerPosition.height);
          }
          tableProp = this.calcPdfPosition(tableProp, doc, tableElement);
          const tableImgData = tableElement.toDataURL('image/jpeg');
          // テーブルはヘッダーの下に配置するのでy座標 = headerの高さに配置
          doc.addImage(tableImgData, 'JPEG', 0, headerProp.height, tableProp.width, tableProp.height);
          if (i === tables.length - 1) {
            const blob = doc.output('blob');
            formData = new FormData();
            formData.append('pdf', blob);
            this.pdfDownloadSubject.next(false);
          }
        });
      }
      return formData.get('pdf');
    });
  }
  calcPdfPosition(prop: IOrderPdfPosition, doc: jspdf, canvas: HTMLCanvasElement) {
    prop.ratio = canvas.height / canvas.width;
    prop.width = doc.internal.pageSize.getWidth();
    prop.height = prop.width * prop.ratio;
    return prop;
  }
}
