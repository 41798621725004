
import {map, filter} from 'rxjs/operators';
import { Subject ,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";



export const EVENT_LOADING = '[Broadcast] loading';
export const EVENT_RESOLUTION = '[Broadcast] resolution';

interface BroadcastEvent {
  key: any;
  data?: any;
}

@Injectable()
export class Broadcaster {
  private _eventBus: Subject<BroadcastEvent>;

  constructor() {
    this._eventBus = new Subject<BroadcastEvent>();
  }

  broadcast(key: any, data?: any) {
    this._eventBus.next({key, data});
  }

  on<T>(key: any): Observable<T> {
    return this._eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => <T>event.data));
  }
}
