import * as BreadcrumbActions from '../_actions/breadcrumb.action';
import { IBreadcrumbReducer } from '../_interfaces';

export type Action = BreadcrumbActions.All;

const initialState = {
  list: []
};

export function reducer(state: IBreadcrumbReducer = initialState, action: Action) {
  switch (action.type) {
    case BreadcrumbActions.UPDATE_LIST:
      state = {
        list: action.payload
      };
      return state;
    default:
      return state;
  }
}
