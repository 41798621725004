import { trigger, state, animate, transition, style, keyframes } from '@angular/animations';

export const TOGGLE_ANIMATION = trigger('toggle', [
  state('0', style({ display: 'none', height: '0px', overflow: 'hidden' })),
  state('1' , style({ display: 'block', height: '*', overflow: 'hidden' })),
  transition('0 => 1', animate(300, keyframes([
    style({display: 'none', height: '0px', offset: 0}),
    style({display: 'block', height: '0px', offset: 0.1}),
    style({display: 'block', height: '*', offset: 1.0})
  ]))),
  transition('1 => 0', animate(300, keyframes([
    style({display: 'block', height: '*', offset: 0}),
    style({display: 'block', height: '0px', offset: 0.9}),
    style({display: 'none', height: '0px', offset: 1.0})
  ]))),
]);
