import { JwtHelperService } from '@auth0/angular-jwt';
export const TOKEN_NAME = 'access_token';

export const TokenUtil = {
  /** Token(FirebaseのID Token)を保存 */
  set: (token: string) => {
    localStorage.setItem(TOKEN_NAME, token);
  },

  /** Tokenを取得 */
  get: () => {
    return localStorage.getItem(TOKEN_NAME);
  },

  /** Tokenをクリア */
  remove: () => {
    localStorage.removeItem(TOKEN_NAME);
  },

  /** Tokenが有効かチェック */
  notExpired: () => {
    const token = TokenUtil.get();
    if (token == null) { return false; }
    const jwtHelper = new JwtHelperService();
    // 期限のきれる10秒前には期限が切れた判定にする
    // 期限切れのテストをするには，3540(59分)をセットすると楽
    return token && !jwtHelper.isTokenExpired(token, 10);
  }
};
