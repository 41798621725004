import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { ILifeCarePlanningRequestParams, ILifeCarePlanning } from '../_interfaces';

@Injectable()
export class LifeCarePlanningService {
  constructor(private http: HttpService) {
  }

  fetchLifeCarePlanningsByNursingHome(nursingHomeId: number, from: string = null, to: string = null): Observable<ILifeCarePlanning[]> {
    let params: { [key: string]: any } = {};
    if (from) {
      params = {...params, from: from};
    }
    if (to) {
      params = {...params, to: to};
    }
    return this.http.get(`/api/v2/pro/external/life/nursing-homes/${nursingHomeId}/care-plannings`, params);
  }

  fetchLifeCarePlanningsByResident(nursingHomeId: number, residentId: number): Observable<ILifeCarePlanning[]> {
    return this.http.get(`/api/v2/pro/external/life/nursing-homes/${nursingHomeId}/residents/${residentId}/care-plannings`);
  }

  createLifeCarePlanning(params: Partial<ILifeCarePlanning>, nursingHomeId: number, residentId: number) {
    return this.http.post(`/api/v2/pro/external/life/nursing-homes/${nursingHomeId}/residents/${residentId}/care-plannings`, params);
  }

  deleteLifeCarePlanning(nursingHomeId: number, residentId: number, lifeCarePlanningId: number) {
    return this.http.delete(`/api/v2/pro/external/life/nursing-homes/${nursingHomeId}/residents/${residentId}/care-plannings/${lifeCarePlanningId}`);
  }

  editLifeCarePlanning(params: Partial<ILifeCarePlanning>, nursingHomeId: number, residentId: number, lifeCarePlanningId: number) {
    return this.http.put(`/api/v2/pro/external/life/nursing-homes/${nursingHomeId}/residents/${residentId}/care-plannings/${lifeCarePlanningId}`, params);
  }

  fetchLifeCarePlanning(nursingHomeId: number, residentId: number, lifeCarePlanningId: number): Observable<ILifeCarePlanning> {
    return this.http.get(`/api/v2/pro/external/life/nursing-homes/${nursingHomeId}/residents/${residentId}/care-plannings/${lifeCarePlanningId}`);
  }
}
