import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { IDiaperStock } from '../_interfaces';

@Injectable()
export class DiaperStockService {
  constructor(private http: HttpService) {
  }

  fetchDiaperStocks(nursingHomeId): Observable<IDiaperStock[]> {
    const params: { [key: string]: any } = {
      limit: 10000
    };
    return this.http.get(`/api/v2/pro/nursing-homes/${nursingHomeId}/diaper-stocks`, params);
  }

  fetchDiaperStock(diaperStockId): Observable<IDiaperStock> {
    return this.http.get(`/api/v2/pro/diaper-stocks/${diaperStockId}`);
  }

  fetchDiaperStockHistories(diaperStockId): Observable<IDiaperStock[]> {
    return this.http.get(`/api/v2/pro/diaper-stocks/${diaperStockId}/_history`);
  }
}
