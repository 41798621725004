import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { IResidentHistory, IResidentCareRecord, IResidentsRequestParams, IResident, IResidentSensorDataSet, IResidentGoodTree } from '../_interfaces';

@Injectable()
export class ResidentService {
  constructor(private http: HttpService) {}

  createResident(nursing_home_id: number, params: Partial<IResident>) {
    return this.http.post(
      '/api/v2/pro/nursing-homes/' + nursing_home_id + '/residents',
      params
    );
  }

  updateResident(params: Partial<IResident>) {
    return this.http.put('/api/v2/pro/residents/:id', params);
  }

  deleteResident(residentId: number) {
    return this.http.delete('/api/v2/pro/residents/:id', { id: residentId });
  }

  retireResident(residentId: number) {
    return this.http.delete('/api/v2/pro/residents/:id', { id: residentId });
  }

  reactivationResident(residentId: number) {
    return this.http.put('/api/v2/pro/residents/:id/reactivation', {
      id: residentId,
    });
  }

  fetchResidents({
    limit = null,
    nursingHomeId = null,
  }: Partial<IResidentsRequestParams>): Observable<IResident[]> {
    let params: { [key: string]: any } = {};
    if (limit) {
      params = { ...params, limit: limit };
    }
    if (nursingHomeId) {
      return this.http.get<IResident[]>(
        '/api/v2/pro/nursing-homes/' + nursingHomeId + '/residents',
        params
      );
    } else {
      return this.http.get<IResident[]>('/api/v2/pro/residents', params);
    }
  }

  fetchResident(residentId: number): Observable<IResident> {
    return this.http.get<IResident>('/api/v2/pro/residents/:id', {
      id: residentId,
    });
  }

  fetchGoodTreeResident(nursingHomeId: number): Observable<IResidentGoodTree[]> {
    return this.http.get<IResidentGoodTree[]>('/api/v2/pro/nursing-homes/:id/residents/external/good-tree', {
      id: nursingHomeId,
    });
  }

  fetchSensorDataSets({ residentId = null, limit = null, sort = null, from = null, to = null }): Observable<IResidentSensorDataSet[]> {
    let params: { [key: string]: any } = {};
    if (limit) {
      params = { ...params, limit };
    }
    if (sort) {
      params = { ...params, sort };
    }
    if (from && to) {
      params = { ...params, from, to };
    }
    return this.http.get(
      '/api/v2/pro/residents/' + residentId + '/ultra-sonic-data-sets',
      params
    );
  }

  fetchCareRecords(residentId: number, { limit = null, sort = null, from = null, to = null }): Observable<IResidentCareRecord[]> {
    let params: { [key: string]: any } = {};
    if (limit) {
      params = { ...params, limit };
    }
    if (sort) {
      params = { ...params, sort };
    }
    if (from && to) {
      params = { ...params, from, to };
    }
    return this.http.get(
      '/api/v2/pro/residents/' + residentId + '/care-records',
      params
    );
  }

  fetchCareRecord(careRecordId: number): Observable<IResidentCareRecord> {
    return this.http.get<IResidentCareRecord>('/api/v2/pro/care-records/:id/', {
      id: careRecordId,
    });
  }

  fetchResidentHistory(residentId: number, { limit = 1000, sort = null, from = null, to = null }): Observable<IResidentHistory[]> {
    let params: { [key: string]: any } = {};
    if (limit) {
      params = { ...params, limit };
    }
    if (sort) {
      params = { ...params, sort };
    }
    if (from) {
      params = { ...params, from };
    }
    if (to) {
      params = { ...params, to };
    }
    return this.http.get<IResidentHistory[]>(
      '/api/v2/pro/residents/' + residentId + '/_history',
      params
    );
  }
}
