import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MdlPopoverModule } from '@angular-mdl/popover';
import { HeaderComponent } from './header.component';
import { DashboardSettingModule } from '../_components/dashboard-setting/dashboard-setting.module';
import { PipeModule } from '../_pipes/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MdlPopoverModule,
    PipeModule,
    DashboardSettingModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {
}
