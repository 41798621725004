import { Action } from '@ngrx/store';
import { ICompany, IResident } from '../_interfaces';

export const UPDATE  = '[Company] update';
export const SHOW_COMPANY_MENU  = '[Company] show_company_menu';
export const UPDATE_NURSING_HOME_ID  = '[Company] update_nursing_home_id';
export const UPDATE_NURSING_HOME_UNIT_ID  = '[Company] update_nursing_home_unit_id';
export const UPDATE_RESIDENT_ID  = '[Company] update_resident_id';
export const UPDATE_RESIDENTS  = '[Company] update_residents';

export class Update implements Action {
  readonly type = UPDATE;

  constructor(public payload: ICompany) {}
}

export class ShowCompanyMenu implements Action {
  readonly type = SHOW_COMPANY_MENU;

  constructor(public payload: boolean) {}
}

export class UpdateNursingHomeId implements Action {
  readonly type = UPDATE_NURSING_HOME_ID;

  constructor(public payload: number) {}
}

export class UpdateNursingHomeUnitId implements Action {
  readonly type = UPDATE_NURSING_HOME_UNIT_ID;

  constructor(public payload: number) {}
}


export class UpdateResidentId implements Action {
  readonly type = UPDATE_RESIDENT_ID;

  constructor(public payload: number) {}
}

interface IUpdateResidents {
  nursingHomeId: number;
  residents: IResident[];
}
export class UpdateResidents implements Action {
  readonly type = UPDATE_RESIDENTS;

  constructor(public payload: IUpdateResidents) {}
}

export type All = Update | ShowCompanyMenu | UpdateNursingHomeId | UpdateNursingHomeUnitId | UpdateResidentId | UpdateResidents;
