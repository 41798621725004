import * as UserActions from '../_actions/user.action';
import { IUserReducer } from '../_interfaces';

export type Action = UserActions.All;

const initialState = {
  detail: null,
  isLogin: false
};

export function reducer(state: IUserReducer = initialState, action: Action) {
  switch (action.type) {
    case UserActions.FETCH:
      state = {
        ...state,
        isLogin: true,
        detail: action.payload
      };
      return state;
    case UserActions.LOGIN:
      state = {
        ...state,
        isLogin: true
      };
      return state;
    case UserActions.LOGOUT:
      state = {
        detail: null,
        isLogin: false
      };
      return state;
    default:
      return state;
  }
}
