import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { INursingHomeDiaper, INursingHomeLaxative, ICreateNursingHomeDiaperRequestParams } from '../_interfaces';

interface IUpdateDiaper {
  diaperId: number;
  cost: number;
  caseCost: number;
}

@Injectable()
export class NursingHomeDiaperService {
  constructor(private http: HttpService) {
  }

  fetchNursingHomeDiapers(nursingHomeId, params?): Observable<INursingHomeDiaper[]> {
    return this.http.get(`/api/v2/pro/nursing-homes/${nursingHomeId}/diapers`, params);
  }

  fetchNursingHomeLaxatives(nursingHomeId): Observable<INursingHomeLaxative[]> {
    return this.http.get(`/api/v2/pro/nursing-homes/${nursingHomeId}/laxatives`);
  }

  createNursingHomeDiaper(nursingHomeId: number, params: ICreateNursingHomeDiaperRequestParams) {
    return this.http.post(`/api/v2/pro/nursing-homes/${nursingHomeId}/diapers`, params);
  }

  fetchDiaper(diaperId: number): Observable<INursingHomeDiaper> {
    return this.http.get(`/api/v2/pro/diapers/${diaperId}`);
  }

  updateDiaper(diaperId: number, params) {
    return this.http.put<IUpdateDiaper>(`/api/v2/pro/diapers/${diaperId}`, params);
  }

  deleteDiaper(diaperId: number) {
    return this.http.delete(`/api/v2/pro/diapers/${diaperId}`);
  }
}
