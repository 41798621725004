import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  IBDeviceColonBatteryData,
  IBDeviceGasBatteryData,
  IBDeviceGasUsdData,
  IBDeviceGasIaqData,
  IBDeviceDataRequestParams,
  IBDeviceDataByIdRequestParams,
  IBDeviceGasAccelerationData,
  IBDeviceColonAccelerationData,
  IBDeviceColonUsdData,
  IBDeviceColonAverageData,
  IBDeviceDataByResidentIdRequestParams
} from 'app/_interfaces';

@Injectable()
export class BDeviceDataService {
  constructor(private http: HttpService) {
  }

  // デバイス準拠ガスバッテリー取得
  fetchGasBatteryDataById(
                  bDeviceId: number,
                  {
                    from = null,
                    to = null
                  }: Partial<IBDeviceDataByIdRequestParams>): Observable<IBDeviceGasBatteryData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceGasBatteryData[]>('/api/v2/pro/b-devices/gas/' + bDeviceId + '/battery', params);
  }

  // 入居者準拠ガスバッテリー取得
  fetchGasBatteryDataByResidentId(
                    residentId: number,
                    {
                      from = null,
                      to = null
                    }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceGasBatteryData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to,
      limit: 1000000
    };
    return this.http.get<IBDeviceGasBatteryData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/gas/battery', params);
  }

  // デバイス準拠ガス加速度データ取得
  fetchGasAccelerationDataById(
                  bDeviceId: number,
                  {
                    from = null,
                    to = null
                  }: Partial<IBDeviceDataByIdRequestParams>): Observable<IBDeviceGasAccelerationData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceGasAccelerationData[]>('/api/v2/pro/b-devices/gas/' + bDeviceId + '/acceleration', params);
  }

  // 入居者準拠ガス加速度データ取得
  fetchGasAccelerationDataByResidentId(
                    residentId: number,
                    {
                      from = null,
                      to = null,
                    }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceGasAccelerationData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to,
      limit: 1000000
    };
    return this.http.get<IBDeviceGasAccelerationData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/gas/acceleration', params);
  }

  // デバイス準拠ガスデータ取得
  fetchGasDataById(
                  bDfreeDeviceId: number,
                  {
                    from = null,
                    to = null,
                  }: Partial<IBDeviceDataRequestParams>): Observable<IBDeviceGasUsdData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceGasUsdData[]>('/api/v2/pro/b-devices/gas/' + bDfreeDeviceId + '/ultra-sonic-data', params);
  }

  // 入居者準拠ガスデータ取得
  fetchGasDataByResidentId(
                    residentId: number,
                    {
                      from = null,
                      to = null,
                    }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceGasUsdData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to,
      limit: 100000,
    };
    return this.http.get<IBDeviceGasUsdData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/gas/ultra-sonic-data', params);
  }

  // デバイス準拠ガスIAQ取得
  fetchGasIaqById(
                  bDfreeDeviceId: number,
                  {
                    from = null,
                    to = null,
                  }: Partial<IBDeviceDataRequestParams>): Observable<IBDeviceGasIaqData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceGasIaqData[]>
      ('/api/v2/pro/b-devices/gas/' + bDfreeDeviceId + '/ultra-sonic-data', params);
  }

  // 入居者準拠ガスIAQ取得
  fetchGasIaqByResidentId(
                    residentId: number,
                    {
                      from = null,
                      to = null,
                    }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceGasIaqData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to,
      limit: 1000000,
    };
    return this.http.get<IBDeviceGasIaqData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/gas/iaq-collective', params);
  }


  // デバイス準拠コロンバッテリーデータ取得
  fetchColonBatteryDataById(
                  bDeviceId: number,
                  {
                    from = null,
                    to = null
                  }: Partial<IBDeviceDataByIdRequestParams>): Observable<IBDeviceColonBatteryData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonBatteryData[]>('/api/v2/pro/b-devices/colon/' + bDeviceId + '/battery', params);
  }

  // デバイス準拠コロン加速度データ取得
  fetchColonAccelerationDataById(
                  bDeviceId: number,
                  {
                    from = null,
                    to = null
                  }: Partial<IBDeviceDataByIdRequestParams>): Observable<IBDeviceColonAccelerationData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonAccelerationData[]>('/api/v2/pro/b-devices/colon/' + bDeviceId + '/acceleration', params);
  }


  // デバイス準拠コロンデータ取得
  fetchColonDataById(
                  bDfreeDeviceId: number,
                  {
                    from = null,
                    to = null,
                  }: Partial<IBDeviceDataRequestParams>): Observable<IBDeviceColonUsdData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonUsdData[]>
      ('/api/v2/pro/b-devices/colon/' + bDfreeDeviceId + '/ultra-sonic-data', params);
  }

  // デバイス準拠コロン平均データ取得
  fetchColonAverageDataById(
                bDfreeDeviceId: number,
                  {
                    from = null,
                    to = null,
                  }: Partial<IBDeviceDataRequestParams>): Observable<IBDeviceColonAverageData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonAverageData[]>
      ('/api/v2/pro/b-devices/colon/' + bDfreeDeviceId + '/average', params);
  }
  // 入居者準拠コロン（左）バッテリーデータ取得
  fetchColonLBatteryDataByResidentId(
                  residentId: number,
                  {
                    from = null,
                    to = null
                  }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceColonBatteryData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonBatteryData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/colon-l/battery', params);
  }
  // 入居者準拠コロン（右）バッテリーデータ取得
  fetchColonRBatteryDataByResidentId(
                  residentId: number,
                  {
                    from = null,
                    to = null
                  }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceColonBatteryData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonBatteryData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/colon-r/battery', params);
  }
  // 入居者準拠コロン（左）加速度データ取得
  fetchColonLAccelerationDataByResidentId(
                  residentId: number,
                  {
                    from = null,
                    to = null
                  }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceColonAccelerationData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonAccelerationData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/colon-l/acceleration', params);
  }
  // 入居者準拠コロン（右）加速度データ取得
  fetchColonRAccelerationDataByResidentId(
                  residentId: number,
                  {
                    from = null,
                    to = null
                  }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceColonAccelerationData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonAccelerationData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/colon-r/acceleration', params);
  }
  // 入居者準拠コロン（左）データ取得
  fetchColonLDataByResidentId(
                  residentId: number,
                  {
                    from = null,
                    to = null,
                  }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceColonUsdData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonUsdData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/colon-l/ultra-sonic-data', params);
  }
  // 入居者準拠コロン（右）データ取得
  fetchColonRDataByResidentId(
                  residentId: number,
                  {
                    from = null,
                    to = null,
                  }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceColonUsdData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonUsdData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/colon-r/ultra-sonic-data', params);
  }
  // 入居者準拠コロン（左）平均データ取得
  fetchColonLAverageDataByResidentId(
                residentId: number,
                  {
                    from = null,
                    to = null,
                  }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceColonAverageData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonAverageData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/colon-l/average', params);
  }
  // 入居者準拠コロン（右）平均データ取得
  fetchColonRAverageDataByResidentId(
                residentId: number,
                  {
                    from = null,
                    to = null,
                  }: Partial<IBDeviceDataByResidentIdRequestParams>): Observable<IBDeviceColonAverageData[]> {
    if (!from || !to) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to
    };
    return this.http.get<IBDeviceColonAverageData[]>('/api/v2/pro/b-devices/residents/' + residentId + '/colon-r/average', params);
  }
}
