import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { INursingHomeRequestParams, INursingHome, INursingHomeGoodTree } from '../_interfaces';

@Injectable()
export class NursingHomeService {
  constructor(private http: HttpService) {
  }

  createNursingHome(params: Partial<INursingHome>, companyId: number) {
    return this.http.post(`/api/v2/pro/nursing-home-groups/${companyId}/nursing-homes`, params);
  }

  deleteNursingHome(nursingHomeId) {
    return this.http.delete('/api/v2/pro/nursing-homes/:id', {id: nursingHomeId});
  }

  editNursingHome(params: Partial<INursingHome>) {
    return this.http.put(`/api/v2/pro/nursing-homes/${params.id}`, params);
  }

  fetchNursingHome(nursingHomeId: number): Observable<INursingHome> {
    return this.http.get<INursingHome>('/api/v2/pro/nursing-homes/:id', {id: nursingHomeId});
  }

  fetchNursingHomeWhole(nursingHomeId: number): Observable<INursingHome> {
    return this.http.get<INursingHome>('/api/v2/pro/nursing-homes/:id/whole', {id: nursingHomeId});
  }

  fetchNursingHomeGoodTree(nursingHomeId: number): Observable<INursingHomeGoodTree[]> {
    return this.http.get<INursingHomeGoodTree[]>('/api/v2/pro/nursing-homes/:id/external/gd-list', {id: nursingHomeId});
  }

  setGdTokenForNursingHome(nursingHomeId) {
    return this.http.put('/api/v2/pro/nursing-homes/:id/external/set-gd-token', {id: nursingHomeId});
  }

  fetchNursingHomes({
                      limit = null,
                      companyId = null,
                      name = null,
                      planStatus = null,
                      email = null
                    }: Partial<INursingHomeRequestParams>): Observable<INursingHome[]> {
    let params: { [key: string]: any } = {};
    if (planStatus) {
      params = {...params, planStatus: 'ture'};
    }
    if (name) {
      params = {...params, name: name};
    }
    if (email) {
      params = {...params, email: email};
    }
    if (limit) {
      params = {...params, limit: limit};
    }
    if (companyId) {
      return this.http.get(`/api/v2/pro/nursing-home-groups/${companyId}/nursing-homes`, params);
    } else {
      return this.http.get('/api/v2/pro/nursing-homes', params);
    }
  }
}
