import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IOrderMailSetting, IOrderMail } from '../_interfaces';

@Injectable()
export class OrderMailSettingService {
  constructor(private http: HttpService) {
  }

  fetchOrderMailSettings(): Observable<IOrderMailSetting[]> {
    return this.http.get<IOrderMailSetting[]>('/api/v2/pro/master/order-mail-settings');
  }

  fetchOrderMailSetting(orderMailSettingId: number): Observable<IOrderMailSetting> {
    return this.http.get<IOrderMailSetting>('/api/v2/pro/master/order-mail-settings/' + orderMailSettingId);
  }

  createOrderMailSetting(params: Partial<IOrderMailSetting>) {
    return this.http.post('/api/v2/pro/master/order-mail-settings', params);
  }

  updateOrderMailSetting(params: Partial<IOrderMailSetting>) {
    return this.http.put('/api/v2/pro/master/order-mail-settings/:id', params);
  }

  sendMail(id: number, fileName: string, template: number, params: Partial<IOrderMail>) {
    const form = new FormData();
    form.append('pdf', params['pdf'], fileName);
    return this.http.postForm('/api/v2/pro/orders/' + id + '/send-to-maker?template=' + template, form);
  }
}
