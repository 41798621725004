import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { INursingHomeUnitRequestParams, INursingHomeUnit } from '../_interfaces';

@Injectable()
export class NursingHomeUnitService {
  constructor(private http: HttpService) {
  }

  createNursingHomeUnit(params: Partial<INursingHomeUnit>, nursingHomeId: number) {
    return this.http.post(`/api/v2/pro/nursing-homes/${nursingHomeId}/nursing-home-units`, params);
  }

  deleteNursingHomeUnit(nursingHomeUnitId) {
    return this.http.delete('/api/v2/pro/nursing-home-units/:id', {id: nursingHomeUnitId});
  }

  editNursingHomeUnit(params: Partial<INursingHomeUnit>) {
    return this.http.put(`/api/v2/pro/nursing-home-units/${params.id}`, params);
  }

  fetchNursingHomeUnit(nursingHomeUnitId: number): Observable<INursingHomeUnit> {
    return this.http.get<INursingHomeUnit>('/api/v2/pro/nursing-home-units/:id', {id: nursingHomeUnitId});
  }

  fetchNursingHomeUnits({
                      nursingHomeId = null,
                      name = null
                    }: Partial<INursingHomeUnitRequestParams>): Observable<INursingHomeUnit[]> {
    let params: { [key: string]: any } = {};
    if (name) {
      params = {...params, name: name};
    }
    if (nursingHomeId) {
      return this.http.get(`/api/v2/pro/nursing-homes/${nursingHomeId}/nursing-home-units`, params);
    } else {
      return this.http.get('/api/v2/pro/nursing-home-units', params);
    }
  }
}
