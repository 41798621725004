import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ICareRecord, ICareRecordRequestParams } from '../_interfaces';

@Injectable()
export class CareRecordService {
  constructor(private http: HttpService) {
  }

  fetchCareRecords({
                    from = null,
                    to = null,
                    residentId = null,
                    limit = 10000
                  }: Partial<ICareRecordRequestParams>): Observable<ICareRecord[]> {
    const params: { [key: string]: any } = {
      residentId: residentId,
      from: from,
      to: to,
      limit: limit
    };
    return this.http.get<ICareRecord[]>('/api/v2/pro/residents/:residentId/care-records', params);
  }

}
