import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  IBDevice,
  IBDeviceRequestParams,
  IBDeviceBatteryData,
  IBDeviceBatteryParams,
  ISerialNumber
} from '../_interfaces';

@Injectable()
export class BDeviceService {
  constructor(private http: HttpService) {
  }

  traverseDevices({
                 limit = 20,
                 serialNumber = null,
               }: Partial<IBDeviceRequestParams>): Observable<IBDevice[]> {
    let params: { [key: string]: any } = {
      limit: limit
    };

    if (serialNumber) {
      params = {...params, serial_number: serialNumber};
    }

    return this.http.get<IBDevice[]>('/api/v2/pro/traversal/dfree-b-devices', params);
  }

  serialNumbers(): Observable<ISerialNumber[]> {
    return this.http.get<ISerialNumber[]>('/api/v2/pro/dfree-b-devices/serial-number');
  }

  fetchDevices({
                 limit = 20,
                 serialNumber = null,
                 residentId = null,
                 nursingHomeId = null
               }: Partial<IBDeviceRequestParams>): Observable<IBDevice[]> {
    let params: { [key: string]: any } = {
      limit: limit
    };

    if (serialNumber) {
      params = {...params, serialNumber: serialNumber};
    }

    if (nursingHomeId) {
      return this.http.get<IBDevice[]>('/api/v2/pro/nursing-homes/:id/dfree-b-devices', {id: nursingHomeId});
    } else {
      return this.http.get<IBDevice[]>('/api/v2/pro/dfree-b-devices', params);
    }
  }

  fetchBDevicesByNursingHome({
                 nursingHomeId = null
               }: Partial<IBDeviceRequestParams>): Observable<IBDevice[]> {
    return this.http.get<IBDevice[]>('/api/v2/pro/nursing-homes/:id/dfree-b-devices', {id: nursingHomeId});
  }

  fetchBDevice(deviceId: number): Observable<IBDevice> {
    return this.http.get<IBDevice>('/api/v2/pro/dfree-b-devices/:id', {id: deviceId});
  }

  createBDevice(params: Partial<IBDevice>) {
    return this.http.post('/api/v2/pro/dfree-b-devices', params);
  }

  editBDevice(params: Partial<IBDevice>) {
    return this.http.put('/api/v2/pro/dfree-b-devices/:id', params);
  }

  updateBDevice(params: Partial<IBDevice>) {
    return this.http.put('/api/v2/pro/dfree-b-devices/:id', params);
  }
}
