import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as BreadcrumbActions from '../_actions/breadcrumb.action';
import { IAppReducerState } from '../_reducers';
import { IBreadcrumb } from '../_interfaces';

@Injectable()
export class BreadcrumbService {
  readonly TYPE_COMPANY  = 'company';
  readonly TYPE_NURSING_HOMES  = 'nursingHomes';
  readonly TYPE_RESIDENTS  = 'residents';
  readonly TYPE_NURSING_HOME_UNITS  = 'units';
  readonly TYPE_DIAPER_STOCKS  = 'stocks';
  readonly TYPE_LIFE_CARE_PLANNING  = 'life-care-plannings';

  constructor(private store: Store<IAppReducerState>) {
  }

  /** パンくずを保存 */
  set(breadcrumb: Partial<IBreadcrumb>[]): void {
    setTimeout(() =>  {
      this.store.dispatch(new BreadcrumbActions.UpdateList(breadcrumb));
    });
  }
}
