import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpService } from './_services/http.service';
import * as UserActions from './_actions/user.action';
import { IUser, INursingHome } from './_interfaces';
import { GuestRoutes, MemberRoutes, AdminRoutes, RootRoutes } from './app-routing.module';
import { Broadcaster } from './_services/broadcast.service';

// slackにエラー通知
@Injectable()
export class CustomErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }

  handleError(err: Response) {
    super.handleError(err);
  }
}

interface IRouterEvent {
  role: string;
  callback?: (data?: any) => void;
}

// ルーティング設定
@Injectable()
export class CustomRouter {
  constructor(private injector: Injector,
              private bc: Broadcaster) {
    this.bc.on<IRouterEvent>('RouterUpdate')
      .subscribe((data) => {
        const {role, callback = () => {}} = data;
        const routes = this._setRouter(role);
        this.injector.get(Router).resetConfig(routes);
        callback();
      });
  }

  load(): Promise<void|Routes> {
    return new Promise<void|Routes>((resolve, _reject) => {
      this.injector.get(HttpService).get<IUser>('/api/v2/pro/users/_self', null).subscribe((user) => {
        this.injector.get(HttpService).get<INursingHome>('/api/v2/pro/nursing-homes/:id', {id: user.nursingHomeId})
        .subscribe(nursingHome => {
          user.nursingHomeGroupId = nursingHome.nursingHomeGroupId;
          user.role = user.professionalRole;
          const store = this.injector.get(Store);
          const routes = this._setRouter(user.role);

          store.dispatch(new UserActions.Fetch(user));
          this.injector.get(Router).resetConfig(routes);
          resolve(routes);

        });
      }, () => {
        resolve();
      });
    });
  }

  // 介護士用のRouterをセット
  private _setRouter(role: string): Routes {
    const {ROOT, ADMIN, MEMBER} = this.injector.get('CONSTANTS').ROLE;

    switch (role) {
      case ROOT.value:
        return RootRoutes;
      case ADMIN.value:
        return AdminRoutes;
      case MEMBER.value:
        return MemberRoutes;
      default:
        return GuestRoutes;
    }
  }
}
