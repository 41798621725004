// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// local development env & local DB
export const environment = {
  production: false,
  baseUrl: 'http://localhost:4200',
  apiUrl: 'https://dev-api.dfree.io',
  displayErrors: false,
  firebase: {
    apiKey: 'AIzaSyD68k8BuobEH9n9nn9l-_jST3MRpFQr4GM',
    projectId: 'dev-dfree-cons',
  },
};
