import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { IResidentsRequestParams, IResident, IResidentSensorDataSet, IResidentGoodTree } from '../_interfaces';

@Injectable()
export class LifeService {
  constructor(private http: HttpService) {}

  fetchLifeResidents({
    limit = null,
    nursingHomeId = null,
  }: Partial<IResidentsRequestParams>): Observable<IResident[]> {
    let params: { [key: string]: any } = {};
    if (limit) {
      params = { ...params, limit: limit };
    }
    return this.http.get<IResident[]>('/api/v2/pro//external/life/nursing-homes/' + nursingHomeId + '/residents', params);
  }
}
