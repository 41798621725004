import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  IResidentBDevice,
  IResidentBDeviceSerialNumber,
} from '../_interfaces';

@Injectable()
export class ResidentBDeviceService {
  constructor(private http: HttpService) {
  }

  fetchResidentBDevices(residentId: number): Observable<IResidentBDevice[]> {
    return this.http.get<IResidentBDevice[]>('/api/v2/pro/residents/' + residentId + '/resident-b-devices');
  }

  createResidentBDevice(residentId: number, serialNumber: string) {
    return this.http.post('/api/v2/pro/residents/' + residentId + '/resident-b-devices', {'serialNumber': serialNumber});
  }

  deleteResidentBDevice(id: number) {
    return this.http.delete('/api/v2/pro/resident-b-devices/:id', {id: id});
  }
}
