import { Action } from '@ngrx/store';
import { IUser } from '../_interfaces';

export const FETCH  = '[User] fetch';
export const LOGIN  = '[User] login';
export const LOGOUT  = '[User] logout';

export class Fetch implements Action {
  readonly type = FETCH;

  constructor(public payload: IUser) {}
}
export class Login implements Action {
  readonly type = LOGIN;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export type All = Fetch | Login | Logout;
