import { Component, Inject, OnInit } from '@angular/core';
import { BreadcrumbService } from '../_services/breadcrumb.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  constructor(private bs: BreadcrumbService, @Inject('CONSTANTS') public CONSTANTS) {
    this.bs.set([]);
  }

}
