import { ActionReducerMap } from '@ngrx/store';
import * as UserReducer from './user.reducer';
import * as CompanyReducer from './company.reducer';
import * as BreadcrumbReducer from './breadcrumb.reducer';
import { IUserReducer, ICompanyReducer, IBreadcrumbReducer } from '../_interfaces';

export interface IAppReducerState {
  user: IUserReducer;
  company: ICompanyReducer;
  breadcrumb: IBreadcrumbReducer;
}

export const reducers: ActionReducerMap<IAppReducerState> = {
  user: UserReducer.reducer,
  company: CompanyReducer.reducer,
  breadcrumb: BreadcrumbReducer.reducer
};
