import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FADE_ANIMATION } from '../../_animations';
import { Broadcaster, EVENT_RESOLUTION } from '../../_services/broadcast.service';

@Component({
  selector: 'app-dashboard-setting',
  templateUrl: './dashboard-setting.component.html',
  styleUrls: ['./dashboard-setting.component.scss'],
  animations: [FADE_ANIMATION]
})
export class DashboardSettingComponent implements OnInit {
  @Input() set open(isOpen: boolean) {
    this.isOpen = isOpen;
  }
  @Output() openChange = new EventEmitter();

  resolution: number;
  displayWidth: number = window.innerWidth;
  isOpen = false;

  constructor(private broadcaster: Broadcaster) {
  }

  ngOnInit() {
    const resolution = localStorage.getItem('resolution');
    this.resolution = parseInt(resolution, 10);
  }

  clickClose() {
    event.preventDefault();
    this.isOpen = false;
    this.openChange.emit(this.isOpen);
  }

  changeResolution(resolution: number) {
    this.resolution = resolution;
    localStorage.setItem('resolution', (resolution || '').toString());
    this._emitResolutionEvent(resolution);
  }

  private _emitResolutionEvent(resolution) {
    this.broadcaster.broadcast(EVENT_RESOLUTION, resolution);
  }
}
