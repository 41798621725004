import {of as observableOf,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  ISensorData,
  IUltraSonicData,
  ISensorDataParams,
  IUrineTrendData,
  IActivityData,
} from '../_interfaces';

@Injectable()
export class SensorService {
  constructor(private http: HttpService) {}

  fetchSensorData({
    from = null,
    to = null,
    residentId = null,
  }: Partial<ISensorDataParams>): Observable<ISensorData[]> {
    if (!from || !to || !residentId) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      residentId: residentId,
      from: from,
      to: to,
    };
    return this.http.get<ISensorData[]>(
      '/api/v2/pro/traversal/residents/:residentId/sensor-reaction-resamples',
      params
    );
  }

  fetchRawData({
    from = null,
    to = null,
    residentId = null,
  }: Partial<ISensorDataParams>): Observable<IUltraSonicData[]> {
    if (!from || !to || !residentId) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to,
      residentId: residentId,
      limit: 50000,
    };

    return this.http
      .get<IUltraSonicData[]>(
        '/api/v2/pro/traversal/residents/:residentId/ultra-sonic-data',
        params
      )
      .pipe(
        map((res: IUltraSonicData[]) => {
          return res;
        })
      );
  }

  fetchUrineTrendData({
    from = null,
    to = null,
    residentId = null,
    limit = 100000,
  }): Observable<IUrineTrendData[]> {
    if (!from || !to || !residentId) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to,
      residentId: residentId,
      limit: limit,
    };

    return this.http
      .get<any[]>(
        '/api/v2/pro/residents/:residentId/urine-trend-analysis',
        params
      )
      .pipe(
        map((res: IUrineTrendData[]) => {
          return res;
        })
      );
  }

  fetchActivityData({
    from = null,
    to = null,
    residentId = null,
    limit = 100000,
  }): Observable<IActivityData[]> {
    if (!from || !to || !residentId) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      from: from,
      to: to,
      residentId: residentId,
      limit: limit,
    };

    return this.http
      .get<any[]>(
        '/api/v2/pro/residents/:residentId/activity-status-analysis',
        params
      )
      .pipe(
        map((res: IActivityData[]) => {
          return res;
        })
      );
  }
}
