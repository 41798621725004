import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IDiaperDelivery, IDiaperDeliveryRequestParams } from '../_interfaces';

@Injectable()
export class DiaperDeliveryService {
  constructor(private http: HttpService) {
  }

  fetchDiaperDeliveries({
                 nursingHomeId = null,
               }: Partial<IDiaperDeliveryRequestParams>): Observable<IDiaperDelivery[]> {
    let params: { [key: string]: any } = {};
    if (nursingHomeId) {
      params = {...params, nursingHomeId: nursingHomeId};
    }
    return this.http.get<IDiaperDelivery[]>('/api/v2/pro/diaper-deliveries', params);
  }

  fetchDiaperDelivery(diaperDeliveryId: number): Observable<IDiaperDelivery> {
    return this.http.get<IDiaperDelivery>('/api/v2/pro/diaper-deliveries/:id', {id: diaperDeliveryId});
  }

  createDiaper(params: Partial<IDiaperDelivery>) {
    return this.http.post('/api/v2/pro/diaper-deliveries', params);
  }

  editDiaper(params: Partial<IDiaperDelivery>) {
    return this.http.put('/api/v2/pro/diaper-deliverie/:id', params);
  }

  updateDiaper(params: Partial<IDiaperDelivery>) {
    return this.http.put('/api/v2/pro/diaper-deliverie/:id', params);
  }

  deleteDiaper(diaperDeliveryId: number) {
    return this.http.delete('/api/v2/pro/diaper-deliverie/:id', {id: diaperDeliveryId});
  }
}

