import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  IDevice,
  IDeviceRequestParams,
  IDeviceBatteryData,
  IDeviceBatteryParams,
  IDeviceSensorDataSet,
  ISerialNumber,
  IDeviceAccelerationData,
  IDeviceAccelerationParams,
  IDevicePostureData,
  IDevicePostureParams
} from '../_interfaces';

@Injectable()
export class DeviceService {
  constructor(private http: HttpService) {
  }

  traverseDevices({
                 limit = 20,
                 serialNumber = null,
               }: Partial<IDeviceRequestParams>): Observable<IDevice[]> {
    let params: { [key: string]: any } = {
      limit: limit
    };

    if (serialNumber) {
      params = {...params, serial_number: serialNumber};
    }

    return this.http.get<IDevice[]>('/api/v2/pro/traversal/dfree-devices', params);
  }

  serialNumbers(): Observable<ISerialNumber[]> {
    return this.http.get<ISerialNumber[]>('/api/v2/pro/dfree-devices/serial-number');
  }

  fetchDevices({
                 limit = 20,
                 serialNumber = null,
                 residentId = null,
                 nursingHomeId = null
               }: Partial<IDeviceRequestParams>): Observable<IDevice[]> {
    let params: { [key: string]: any } = {
      limit: limit
    };

    if (serialNumber) {
      params = {...params, serialNumber: serialNumber};
    }

    if (nursingHomeId) {
      return this.http.get<IDevice[]>('/api/v2/pro/nursing-homes/:id/dfree-devices', {id: nursingHomeId});
    } else {
      return this.http.get<IDevice[]>('/api/v2/pro/dfree-devices', params);
    }
  }

  fetchDevicesByNursingHome({
                 nursingHomeId = null
               }: Partial<IDeviceRequestParams>): Observable<IDevice[]> {
    return this.http.get<IDevice[]>('/api/v2/pro/nursing-homes/:id/dfree-devices', {id: nursingHomeId});
  }

  fetchDevice(deviceId: number): Observable<IDevice> {
    return this.http.get<IDevice>('/api/v2/pro/dfree-devices/:id', {id: deviceId});
  }

  createDevice(params: Partial<IDevice>) {
    return this.http.post('/api/v2/pro/dfree-devices', params);
  }

  editDevice(params: Partial<IDevice>) {
    return this.http.put('/api/v2/pro/dfree-devices/:id', params);
  }

  updateDevice(params: Partial<IDevice>) {
    return this.http.put('/api/v2/pro/dfree-devices/:id', params);
  }

  setDeactive(deviceId: number) {
    return this.http.put('/api/v2/pro/dfree-devices/:id/set-deactivate', {id: deviceId});
  }

  addHonobonoDevice(deviceId: number) {
    return this.http.post('/api/v2/pro/external/honobono/dfree-devices/:id', {id: deviceId});
  }

  updateHonobonoDevice(deviceId: number) {
    return this.http.put('/api/v2/pro/external/honobono/dfree-devices/:id', {id: deviceId});
  }

  deleteHonobonoDevice(deviceId: number) {
    return this.http.delete('/api/v2/pro/external/honobono/dfree-devices/:id', {id: deviceId});
  }

  fetchBatteryData({
                     deviceId = null,
                     from = null,
                     to = null
                   }: Partial<IDeviceBatteryParams>): Observable<IDeviceBatteryData[]> {
    if (!from || !to || !deviceId) {
      return observableOf([]);
    }

    const params: { [key: string]: any } = {
      deviceId: deviceId,
      from: from,
      to: to,
      limit: 500000
    };

    return this.http.get<IDeviceBatteryData[]>('/api/v2/pro/dfree-devices/:deviceId/stats/battery', params);
  }

  fetchSensorDataSets({ deviceId = null, limit = null, sort = null, from = null, to = null }): Observable<IDeviceSensorDataSet[]> {
    let params: { [key: string]: any } = {};
    if (deviceId) {
      params = { ...params, deviceId };
    }
    if (limit) {
      params = { ...params, limit };
    }
    if (sort) {
      params = { ...params, sort };
    }
    if (from && to) {
      params = { ...params, from, to };
    }
    return this.http.get(
      '/api/v2/pro/dfree-devices/:deviceId/ultra-sonic-data-sets',
      params
    );
  }

  fetchAccelerationData({
                     deviceId = null,
                     from = null,
                     to = null,
                     limit = null
                   }: Partial<IDeviceAccelerationParams>): Observable<IDeviceAccelerationData[]> {
    if (!from || !to || !deviceId) {
      return observableOf([]);
    }
    let params: { [key: string]: any } = {};
    if (limit) {
      params = { ...params, limit };
    }
    if (from && to) {
      params = { ...params, from, to };
    }
    return this.http.get<IDeviceAccelerationData[]>('/api/v2/pro/dfree-devices/' + deviceId + '/stats/acceleration', params);
  }

  fetchPostureData({
                     deviceId = null,
                     from = null,
                     to = null,
                     limit = null
                   }: Partial<IDevicePostureParams>): Observable<IDevicePostureData[]> {
    if (!from || !to || !deviceId) {
      return observableOf([]);
    }

    let params: { [key: string]: any } = {};
    if (limit) {
      params = { ...params, limit };
    }
    if (from && to) {
      params = { ...params, from, to };
    }

    return this.http.get<IDevicePostureData[]>('/api/v2/pro/dfree-devices/' + deviceId + '/stats/posture', params);
  }
}
