import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MdlSelectModule } from '@angular-mdl/select';
import { DashboardSettingComponent } from './dashboard-setting.component';
import { MdlModule } from '@angular-mdl/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MdlSelectModule,
    MdlModule
  ],
  declarations: [DashboardSettingComponent],
  exports: [DashboardSettingComponent]
})
export class DashboardSettingModule { }
