import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    // private ts: ToastrService
    @Inject(Injector) private injector: Injector
  ) {}

  private get ts(): ToastrService {
    return this.injector.get(ToastrService);
  }

  info(message: string, title?: string) {
    this.ts.info(message, title || 'INFO');
  }

  success(message: string, title?: string) {
    this.ts.success(message, title || 'SUCCESS');
  }

  error(message: string, title?: string) {
    this.ts.error(message, title || 'ERROR');
  }

  warning(message: string, title?: string) {
    this.ts.warning(message, title || 'WARNING');
  }
}
