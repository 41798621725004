import { of as observableOf, Observable, zip, from, interval } from 'rxjs';
import { tap, map, mergeMap, reduce, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from './http.service';
import { ICompanyReducer, ICompanyRequestParams, IResident } from '../_interfaces';
import { ICompany, INursingHome } from '../_interfaces';
import * as CompanyActions from '../_actions/company.action';

@Injectable()
export class CompanyService {
  companiesCache: ICompany[];
  lastSearchParams: string = null;

  constructor(private http: HttpService,
              private store: Store<ICompanyReducer>) {
  }

  createCompany(params: Partial<ICompany>) {
    return this.http.post('/api/v2/pro/nursing-home-groups', params);
  }

  deleteCompany(id: number) {
    return this.http.delete('/api/v2/pro/nursing-home-groups/:id', {id: id});
  }

  editCompany(params: Partial<ICompany>) {
    return this.http.put('/api/v2/pro/nursing-home-groups/:id', params);
  }

  fetchCompany(nursingHomeGroupId: number) {
    return this.http.get<ICompany>('/api/v2/pro/traversal/nursing-home-groups/:id', {id: nursingHomeGroupId}).pipe(tap(data => {
      this.store.dispatch(new CompanyActions.Update(data));
    }));
  }

  fetchCompanies({
                   limit = null,
                   name = null
                 }: Partial<ICompanyRequestParams>) {
    let params: { [key: string]: any } = {};

    if (limit) {
      params = {...params, limit: limit};
    }
    if (name) {
      params = {...params, name: name};
    }

    // キャッシュがあったらそっちを読み込む
    const cacheJson = JSON.stringify(params);
    if (cacheJson === this.lastSearchParams) {
      return observableOf(this.companiesCache);
    }

    return this.http.get<ICompany[]>('/api/v2/pro/nursing-home-groups', params)
    .pipe(
      tap((data: ICompany[]) => {
        this.lastSearchParams = cacheJson;
        this.companiesCache = data.reverse();
      })
    );
  }

  companiesCacheClean() {
    this.lastSearchParams = '';
  }
}
